<template>
  <auth-form-wrapper
    :title="$t('auth.logout.title')"
    :subtitle="$t('auth.logout.subtitle')"
    :error="error"
  >
    <b-form class="auth-form mt-2" @submit.prevent="handleSubmit">
      <!-- Submit Button -->
      <div class="auth-btn-container">
        <b-button type="submit" class="auth-btn auth-btn-primary">
          {{ $t('auth.logout.submit') }}
        </b-button>
      </div>
    </b-form>

    <!-- Cancel -->
    <p class="text-center mt-2">
      <b-link :to="{ name: 'Resumen' }">
        {{ $t('common.cancel') }}
      </b-link>
    </p>
  </auth-form-wrapper>
</template>

<script>
import AuthFormWrapper from '@/auth/components/AuthFormWrapper.vue'
import Vue from 'vue'

export default {
  name: 'Logout',
  
  components: {
    AuthFormWrapper
  },

  mixins: [],

  data() {
    return {
      
      error: null
    }
  },

  computed: {
    
  },

  methods: {
    async handleSubmit() {
      console.log('logout...');
      this.$store.dispatch('logout');

      // await router.replace({ 
      //   name: 'auth-login', 
      //   params: { 
      //     communityId: communitySlug 
      //   } 
      // })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
